<template>
  <div class="session-bg">
    <div class="signup4-container">
      <base-card>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="6"
            class="pa-10"
          >
            <img
              class=""
              src="@/assets/images/reportFE.png"
            >
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="bg-gray-300"
          >
            <v-form
              ref="form"
              class="mt-4"
            >
              <div class="pa-10 mt-3">
                <v-text-field
                  v-model="dataLogin.email"
                  label="Correo"
                  :rules="emailRules"
                  required
                />
                <v-text-field
                  v-model="dataLogin.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  name="input-10-2"
                  label="Contraseña"
                  :counter="0"
                  :rules="[() => !! dataLogin.password || 'Este campo es requerido']"
                  required
                  @click:append="showPassword = !showPassword"
                />
                <vue-recaptcha
                  ref="recaptcha"
                  :sitekey="sitekey"
                  @verify="onVerify"
                  @expired="onExpired"
                />
              </div>
              <v-alert
                v-show="errorAlert"
                type="error"
                dismissible
                dense
                outlined
              >
                Usuario o contraseña incorrecta
              </v-alert>
              <div style="text-align: -webkit-center">
                <v-btn
                  style="background-color:rgb(204 33 40);color:white !important"
                  v-show="showSignIn"
                  @click="singin"
                >
                  <span>Iniciar Sesión</span>
                </v-btn>
              </div>
            </v-form>
          </v-col>
        </v-row>
      </base-card>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import { VueRecaptcha } from 'vue-recaptcha'

  export default {
    components: {
      'vue-recaptcha': VueRecaptcha,
    },
    name: 'Login',
    metaInfo: {
      title: 'Sign in',
    },
    data () {
      return {
        showPassword: false,
        showSignIn: false,
        dataLogin: [],
        errorAlert: false,
        loading: false,
        sitekey: '6LcGf0kqAAAAANa3zW1n_4aGVGtJYiIruIuMQ-W1',
        emailRules: [
          (v) => !!v || 'Este campo es requerido',
          (v) => /.+@.+\..+/.test(v) || 'El formato de correo no es valido.',
        ],
      }
    },
    computed: {
      ...mapGetters(['loggedInUser', 'error']),
    },
    watch: {
      loggedInUser (val) {
        if (val && val.user && val.user.length > 0) {
          this.loading = true
          setTimeout(() => {
            this.$router.push('/')
          }, 200)
        }
      },
      error (val) {
        if (val != null) {
          this.showSignIn = false
          this.errorAlert = true
          this.resetRecaptcha()
          setTimeout(() => {
            this.errorAlert = false
          }, 5000)
          this.hideAlertError()
        }
      },
    },
    methods: {
      ...mapActions(['login', 'clearerror']),
      singin () {
        if (!this.dataLogin.email && !this.dataLogin.password) {
          this.$refs.form.validate()
          this.resetRecaptcha()
        } else {
          this.login({
            ...this.dataLogin,
          })
        }
      },
      hideAlertError () {
        this.clearerror()
      },
      onVerify: function (response) {
        this.showSignIn = true
      },
      onExpired: function () {
        console.log('Expired')
      },
      resetRecaptcha () {
        this.$refs.recaptcha.reset() // Direct call reset method
      },
    },

  }
</script>
<style lang="scss">
.session-bg {
  background-color: white;
  height: 100vh;
  align-items: center;
  place-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}
.signup4-header img {
  width: 200px;
}
.signup4-container img {
  max-height: 300px;
  max-width: 100%;
}
@media only screen and (min-width: 1024px) {
  .signup4-container {
    max-width: 800px;
  }
}
</style>
